import * as React from 'react';
import styled from 'styled-components';
import Anchor from '../Anchor';
import { primaryButtonStyle } from '../../theme';

interface LinkButtonProps {
  url: string;
  className?: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({
  url,
  children,
  className
}) => (
  <StyledAnchor url={url} className={className}>
    {children}
  </StyledAnchor>
);

export default LinkButton;

const StyledAnchor = styled(Anchor)`
  ${primaryButtonStyle};
  text-align: center;
  margin: 0 auto;
  width: 200px;
`;
