import NewsCard from '../../atoms/NewsCard';
import List from '../../atoms/List';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { breakpoint } from '../../theme';

const NewsListTop = () => {
  return (
    <StaticQuery
      query={graphql`
        query NewsListTopQuery {
          allContentfulNews(
            limit: 999
            filter: { releaseDate: { ne: null } }
            sort: { fields: [releaseDate], order: DESC }
          ) {
            edges {
              node {
                newsId
                title
                type
                releaseDate(formatString: "YYYY/MM/DD")
              }
            }
          }
        }
      `}
      render={({ allContentfulNews }) => {
        const newses = allContentfulNews.edges;

        return (
          <NewsWrapList
            dataSource={newses.slice(0, 6)}
            renderRow={(obj, index) => {
              const { newsId, title, type, releaseDate } = obj.node;
              return (
                <li key={index}>
                  <NewsCard
                    category={type}
                    date={releaseDate}
                    title={title}
                    to={`news/${newsId}`}
                  />
                </li>
              );
            }}
            empty={null}
          />
        );
      }}
    />
  );
};

export default NewsListTop;

const NewsWrapList = styled(List)`
  margin: 0 auto;
  padding: 0 10px 10px;
  display: flex;
  @media ${breakpoint.sp} {
    width: fit-content;
    padding: 0 0 3rem 1.5rem;

    > li {
      margin-right: 30px;
      display: inline-block;
      width: 70vw;
      max-width: 230px;
    }
  }
  @media ${breakpoint.tab} {
    max-width: 800px;
    flex-wrap: wrap;
    > li {
      width: calc((99.9% - 60px) / 3);
      margin-bottom: 30px;

      :nth-child(3n-1) {
        margin-left: 30px;
        margin-right: 30px;
      }
    }
  }
`;
