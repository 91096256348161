import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import LazyLoadImage from '../components/atoms/LazyLoadImage';
import NewsListTop from '../components/molecules/NewsListTop';
import LinkButton from '../components/atoms/LinkButton';
import Anchor from '../components/atoms/Anchor';
import React from 'react';
import styled from 'styled-components';
import {
  headingFontSize,
  breakpoint,
  mainColor,
  borderColor,
  bgOpacityColor,
  bgColor,
} from '../components/theme';
import SectionTitle from '../components/atoms/SectionTitle';
import {
  CrocchaLogo,
  AppleStore,
  GooglePlay,
  Mockup,
  HeroImg,
  ServiceBg,
  BgProduct,
  BgProductSp,
  CROCCHA_API,
  CrocchaImg,
  DXSupportMainImg,
  MembersAllImg,
  MetaDesc,
  MetaOGP,
  SDGsTop,
  ServiceCrocchaShop,
  ShopURL
} from '../utils';
import HeadBase from '../components/molecules/HeadBase';
import IconApp from '../../static/images/icon-app.svg';
import IconCart from '../../static/images/icon-cart.svg';
import IconCroccha from '../../static/images/icon-croccha.svg';
import IconSeo from '../../static/images/icon-seo.svg';
import IconSite from '../../static/images/icon-site.svg';

const IndexPage = () => {
  return (
    <BaseLayoutTemplate>
      <HeadBase
        metas={{
          title: 'トップ | tryangle株式会社',
          description: MetaDesc,
          openGraph: {
            type: 'website',
            url: `${CROCCHA_API.SELF_DOMAIN}/`,
            title: 'トップ | tryangle株式会社',
            description: MetaDesc,
            images: [
              {
                url: MetaOGP,
                width: 1200,
                height: 630,
                alt: 'tryangle株式会社',
              },
            ],
            site_name: 'tryangle株式会社',
          },
        }}
      />

      <Hero>
        <h1>作りたいものが作れる世界を</h1>
      </Hero>

      {/* サービス */}
      <Section>
        <MainText>
          私たちは、「作りたいものが作れる世界を」というビジョンを掲げて、
          <br />
          ハンドメイドの世界で教え合えるSNSアプリ「croccha」を運営しています。
          <br />
          そのノウハウを生かして、商品動画制作やIT・DX支援など幅広い事業で
          <br />
          お客様の想いに応えられるように日々取り組んでいます。
        </MainText>
      </Section>

      {/* サービス */}
      <BgSection>
        <SectionTitle title='Service' span='サービス' />
        <Service>
          <h3>
            croccha<span>ハンドメイドユーザーのための教え合えるSNS</span>
          </h3>
          <p>
            <span>「ハンドメイドを教え合う」をコンセプトにITの力で、</span>
            <span>
              ハンドメイドをもっと身近にして、誰でも作りたいを実現できる
            </span>
            <span>作家と作家、作家と企業が繋がる世界を目指しています。</span>
            <span>ハンドメイドをもっと多くの方が便利に楽しむ</span>
            そんな世界を作りたいと考えています。
          </p>
          <ServiceImage src={CrocchaImg} />
          <ServiceLinkButton url='croccha'>詳しく見る</ServiceLinkButton>
        </Service>
        <Service>
          <h3>
            croccha shop
            <span>
              UV/LEDレジン液とハンドメイド材料のお店「クロッチャショップ」
            </span>
          </h3>
          <p>
            <span>
              「作家のためのレジン」を中心にアクセサリーパーツや
            </span>
            <span>
              花材などのハンドメイド材料を販売しています
            </span>
          </p>
          <ServiceImage src={ServiceCrocchaShop} />
          <ServiceShopButton url={ShopURL}>croccha公式ショップ</ServiceShopButton>
          <ServiceShopBtns>
            <ServiceSecondaryButton url='https://www.rakuten.ne.jp/gold/handmade-flower-parts/'>楽天市場店</ServiceSecondaryButton>
            <ServiceSecondaryButton url='https://www.amazon.co.jp/s?me=A2RZC7DHYIY729&marketplaceID=A1VC38T7YXB528'>Amazon店</ServiceSecondaryButton>
          </ServiceShopBtns>
          <ServiceShopBtns>
            <ServiceSecondaryButton url='https://store.shopping.yahoo.co.jp/croccha-shop'>Yahoo!ショッピング</ServiceSecondaryButton>
            <ServiceSecondaryButton url='https://wowma.jp/user/68835247/list'>au PAY マーケット店</ServiceSecondaryButton>
          </ServiceShopBtns>
        </Service>
        <Service>
          <h3>
            SDGs × ハンドメイド
            <span>クリエイターのチカラでアップサイクル商品へ</span>
          </h3>
          <p>
            <span>
              ものづくりにおいて、どうしても端材や製品として世に出せない素材があります。
            </span>
            <span>わたしたちはハンドメイドという「ものづくり」を通じて</span>
            <span>アップサイクル商品として捨てられていた素材を</span>
            <span>
              クリエイターのチカラで生まれ変わらせたいと思っています。
            </span>
            <span>
              クリエイターと企業が繋がり、世の中がもっとよくなる世界を作りたいと考えています。
            </span>
          </p>
          <ServiceImage src={SDGsTop} />
          <ServiceLinkButton url='sdgs-handmade'>詳しく見る</ServiceLinkButton>
        </Service>
        <Service>
          <h3>
            IT・DX支援<span>システム開発・コンサルティングなど</span>
          </h3>
          <p>
            <span>
              crocchaで運用しているネットショップ、SNSサービスのノウハウを生かして、
            </span>
            <span>
              社内の専属エンジニアがSEO対策、セキュリティ対策、ユーザビリティ等
            </span>
            サービス運用に配慮したサイト制作や運用のお手伝いをさせていただきます。
          </p>
          <ServiceImage src={DXSupportMainImg} />
          <ServiceLinkButton url='it-support'>詳しく見る</ServiceLinkButton>
        </Service>
        {/* 資料請求 */}
        <StyledLinkButton url='contact'>
          サービスの資料請求はこちら
        </StyledLinkButton>
      </BgSection>

      {/* ニュース */}
      <Section>
        <SectionTitle title='News' span='ニュース' />
        <ScrollWrap>
          <NewsListTop />
        </ScrollWrap>
        <LinkButton url='news'>一覧を見る</LinkButton>
      </Section>

      {/* ビジネス */}
      <BgSection>
        <SectionTitle title='Business' span='事業領域' />
        <BusinessList>
          <li>
            <div>croccha</div>
            <img src={IconCroccha} alt='croccha' />
          </li>
          <li>
            <div>
              ECコンサル
              <br />
              ECサイト制作
            </div>
            <img src={IconCart} alt='ECコンサル・ECサイト制作' />
          </li>
          <li>
            <div>
              コンテンツマーケティング
              <br />
              SEO対策
            </div>
            <img src={IconSeo} alt='コンテンツマーケティング・SEO対策' />
          </li>
          <li>
            <div>アプリ制作</div>
            <img src={IconApp} alt='アプリ制作' />
          </li>
          <li>
            <div>
              HP制作
              <br />
              バナーイラスト制作
            </div>
            <img src={IconSite} alt='HP制作・バナーイラスト制作' />
          </li>
        </BusinessList>
      </BgSection>

      {/* メンバー */}
      <Section>
        <SectionTitle title='Member' span='メンバー' />
        <MemberText>
          <span>
            エンジニア、デザイナー含め全員が戦略を理解し、お客様の要望を応えるため
          </span>
          <span>
            一人一人がプロフェッショナルとして各分野で活躍しています。
          </span>
          <span>共に働くメンバーに敬意を払い誰もが家族・友人に胸を張って</span>
          会社や仕事を紹介できる、 そんな会社でありたいと思っています。
        </MemberText>
        <MemberImage src={MembersAllImg} alt='トライアングル社員一同' />
        <LinkButton url='company'>もっと見る</LinkButton>
      </Section>

      {/* アプリ案内SP */}
      <BannerSpWrap>
        <CrocchaLogoWrap>
          <LazyLoadImage src={CrocchaLogo} alt='crocchaロゴ' />
        </CrocchaLogoWrap>

        <FooterMessage>
          <div>ハンドメイド好きが</div>
          <div>集まるアプリ</div>
        </FooterMessage>

        <MessageWrap>
          <span>"ハンドメイドを教え合う"をテーマに</span>
          <span>材料とレシピがわかって</span>
          <span>そのままお店の材料を</span>
          <span>直接購入できるアプリです</span>
        </MessageWrap>
        <ButtonAreaSp>
          <ButtonList>
            <WebappLink url='https://web.croccha.com/'>Web版App</WebappLink>
            <StoreLink url='https://itunes.apple.com/jp/app/croccha/id1420803859?ls=1&mt=8'>
              <LazyLoadImage src={AppleStore} alt='App Storeからダウンロード' />
            </StoreLink>
            <StoreLink url='https://play.google.com/store/apps/details?id=com.croccha.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <LazyLoadImage src={GooglePlay} alt='Google Playで手に入れよう' />
            </StoreLink>
          </ButtonList>
          <MockImg src={Mockup} alt='ハンドメイド好きが集まるアプリ' />
        </ButtonAreaSp>
      </BannerSpWrap>

      {/* アプリ案内PC */}
      <BannerPcWrap>
        <BannerContainer>
          <MockImg src={Mockup} alt='ハンドメイド好きが集まるアプリ' />
          <BannerRightSide>
            <LogoContainer>
              <CrocchaLogoWrap>
                <LazyLoadImage src={CrocchaLogo} alt='crocchaロゴ' />
              </CrocchaLogoWrap>

              <FooterMessage>
                <div>ハンドメイド好きが</div>
                <div>集まるアプリ</div>
              </FooterMessage>
            </LogoContainer>
            <MessageWrap>
              <span>"ハンドメイドを教え合う"をテーマに</span>
              <span>材料とレシピがわかって</span>
              <span>そのままお店の材料を</span>
              <span>直接購入できるアプリです</span>
            </MessageWrap>
            <ButtonList>
              <WebappLink url='https://web.croccha.com/'>Web版App</WebappLink>
              <StoreLink url='https://itunes.apple.com/jp/app/croccha/id1420803859?ls=1&mt=8'>
                <LazyLoadImage
                  src={AppleStore}
                  alt='App Storeからダウンロード'
                />
              </StoreLink>
              <StoreLink url='https://play.google.com/store/apps/details?id=com.croccha.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <LazyLoadImage
                  src={GooglePlay}
                  alt='Google Playで手に入れよう'
                />
              </StoreLink>
            </ButtonList>
          </BannerRightSide>
        </BannerContainer>
      </BannerPcWrap>
    </BaseLayoutTemplate>
  );
};

export default IndexPage;

const Hero = styled.div`
  position: relative;
  height: 80vh;
  background-image: url(${HeroImg});
  background-position: center 50%;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  > h1 {
    font-size: ${headingFontSize.xxxLarge};
    color: white;
    z-index: 100;
  }
  @media ${breakpoint.sp} {
    height: 40vh;
    > h1 {
      margin: 0 16px;
      font-size: 34px;
      text-align: center;
      z-index: 100;
    }
  }
  @media ${breakpoint.tabOnly} {
    height: 50vh;
  }

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
  }
`;

const Section = styled.section`
  max-width: 800px;
  padding: 4rem 0 0;
  text-align: center;

  @media ${breakpoint.tabOnly} {
    max-width: none;
    width: 100vw;
  }
`;

const BgSection = styled.section`
  margin-top: 60px;
  padding: 40px 0;
  background-color: ${bgColor};
  text-align: center;
`;

const MainText = styled.p`
  padding: 60px 32px;
  line-height: 1.8;
  background-color: ${bgOpacityColor};
  border-radius: 10px;
  font-weight: bold;
  @media ${breakpoint.sp} {
    border-radius: 0;
  }
`;

const ScrollWrap = styled.div`
  @media ${breakpoint.sp} {
    overflow-x: scroll;

    /* Edgeのスクロールバー非表示 */
    -ms-overflow-style: none;

    /* iPhoneのスクロール */
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
      -webkit-appearance: none;
    }
  }
`;

// フッタ画像（SP）
const BannerSpWrap = styled.div`
  padding-top: 45px;
  text-align: center;
  background-image: url(${ServiceBg});
  background-repeat: repeat;
  margin-top: 80px;
  @media ${breakpoint.tab} {
    display: none;
  }
`;

const ButtonAreaSp = styled.div`
  padding-top: 2rem;
  background-image: url(${BgProductSp});
  background-repeat: repeat;
  background-size: contain;
`;

/* フッタ画像（PC） */
const BannerPcWrap = styled.div`
  background-image: url(${BgProduct}), url(${ServiceBg});
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  margin-top: 80px;
  @media ${breakpoint.sp} {
    display: none;
  }
`;

const BannerContainer = styled.div`
  margin: 0 auto;
  padding: 3rem 1rem 0;
  max-width: 800px;
  display: grid;
  grid-template-columns: 340px 1fr;
  grid-gap: 50px;
`;

// フッタSP/PC共通定義

const MockImg = styled(LazyLoadImage)`
  @media ${breakpoint.sp} {
    width: 80%;
  }
  @media ${breakpoint.tab} {
    grid-column: 1;
    padding-top: 1.5rem;
  }
`;

const CrocchaLogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  padding: 6px;
  border: 1px solid #fff;
  border-radius: 15px;

  @media ${breakpoint.sp} {
    margin: auto;
  }
`;

const BannerRightSide = styled.div`
  grid-column: 2;
  text-align: left;
  color: #fff;
`;

const LogoContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
`;

const FooterMessage = styled.div`
  font-weight: bold;
  color: #fff;
  @media ${breakpoint.sp} {
    margin: 20px 0;
    font-size: 20px;
  }
  @media ${breakpoint.tab} {
    margin-left: 24px;
    font-size: 24px;
  }
`;

const MessageWrap = styled.p`
  @media ${breakpoint.sp} {
    padding: 0 46px;
  }
  color: white;
  font-weight: bold;
  > span {
    /* 改行位置を調整 */
    display: inline-block;
  }
`;

const ButtonList = styled.div`
  @media ${breakpoint.sp} {
    margin: 3rem auto 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media ${breakpoint.tab} {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
`;

const WebappLink = styled(Anchor)`
  text-align: center;
  line-height: 45px;
  color: #fff;
  background: ${mainColor};
  border-radius: 10px;
  font-weight: bold;
  @media ${breakpoint.sp} {
    width: 150px;
    margin-bottom: 1.5rem;
  }
`;

const StoreLink = styled(Anchor)`
  @media ${breakpoint.sp} {
    width: 150px;
    margin-bottom: 1.5rem;
  }
`;

// ビジネス
const BusinessList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 800px;
  margin: 0 auto 40px;
  background-color: #fff;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid ${bgColor};
    width: calc(800px / 3);
    height: calc(800px / 3);
    padding: 34px 0;
    font-size: 16px;
    font-weight: bold;
  }

  @media ${breakpoint.tabOnly} {
    max-width: none;
    width: 100vw;

    li {
      width: calc(100vw / 3);
      height: calc(100vw / 3);
    }
  }

  div {
    display: flex;
    align-items: center;
    height: 50px;
  }

  img {
    width: 100px;
    margin-top: 16px;
  }

  @media ${breakpoint.sp} {
    grid-template-columns: repeat(2, 1fr);
    max-width: none;
    width: 100vw;

    li {
      width: calc(100vw / 2);
      height: calc(100vw / 2);
      padding: 26px 0;
    }

    div {
      font-size: 14px;
      height: 40px;
    }

    img {
      width: 60px;
    }
  }
`;

// 資料請求はこちら
const StyledLinkButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 90px;
  margin: 40px auto;
  border-radius: 8px;
  font-size: 20px;

  background: no-repeat,
    linear-gradient(
      135deg,
      rgba(110, 193, 153, 0.8) 0%,
      rgba(110, 193, 153, 0.8) 50%,
      #6ec199 50%,
      #6ec199 100%
    );
  :hover,
  :active,
  :focus {
    opacity: 0.7;
    background: #6ec199;
    color: #fff;
  }

  @media ${breakpoint.sp} {
    width: 90%;
  }
`;

// サービス
const Service = styled.div`
  max-width: 800px;
  margin: 0 auto 24px;
  padding: 32px 0 40px;
  background-color: #fff;
  border: 1px solid ${borderColor};
  border-radius: 10px;

  @media ${breakpoint.tabOnly} {
    max-width: none;
    width: calc(100vw - 48px);
    margin: 0 24px 24px;
  }

  h3 {
    font-size: 22px;
    margin-bottom: 24px;
    span {
      position: relative;
      display: block;
      margin-top: 8px;
      font-size: 14px;
      font-weight: normal;
      color: #6ec199;
    }
  }

  p {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 1.8;
    span {
      display: block;
    }
  }

  @media ${breakpoint.sp} {
    max-width: none;
    width: calc(100vw - 32px);
    margin: 0 16px 24px;
    padding: 24px 16px 16px;

    h3 {
      font-size: 20px;
      span {
        font-size: 12px;
      }
    }
    p {
      font-size: 14px;
      span {
        display: inline;
      }
    }
  }
`;

const ServiceLinkButton = styled(LinkButton)`
  margin-top: 24px;
`;

const ServiceImage = styled(LazyLoadImage)`
  width: calc(800px * 0.8);
  height: calc((800px * 0.8) * 0.562);
  object-fit: cover;

  @media ${breakpoint.sp} {
    width: calc(100vw - 64px);
    height: calc((100vw - 64px) * 0.562);
  }
`;

// croccha shop
const ServiceShopButton = styled(LinkButton)`
  margin-top: 24px;
  width: 240px;
`;

const ServiceSecondaryButton = styled(LinkButton)`
  color: ${mainColor};
  background: #fff;
  border: 2px solid ${mainColor};
  margin: 0 8px;
  &:hover,
  &:active,
  &:focus {
    color: ${mainColor};
    background: #fff;
    border: 2px solid ${mainColor};
  }
`;

const ServiceShopBtns = styled.div`
  margin-top: 24px;
`;



// メンバー
const MemberImage = styled(LazyLoadImage)`
  margin: 40px auto 30px;
  border-radius: 10px;
  @media ${breakpoint.sp} {
    border-radius: 0;
  }
`;

const MemberText = styled.p`
  line-height: 1.8;
  span {
    display: block;
  }

  @media ${breakpoint.sp} {
    padding: 0 16px;
    span {
      display: inline;
    }
  }
`;
